h1 {
    font-size: 24px;
}

body {
    font-size: 20px;
}

@media only screen and (max-width: 600px) {
    body {
        font-size: 18px;
    }
}

a {
    font-weight: 500;
}

.links {
    a {
        color: blue;
    }

    svg {
        fill: blue;
    }

    &:hover {
        a {
            color: darkblue;
        }

        svg {
            fill: darkblue;
        }
    }
}