button{
    cursor: pointer;
    background-color: #cdcdcd;
    color: black;
    font-size: 16px;
    border-radius: 100px;
    border: none;
    padding: 8px 16px 8px 16px;
    font-family: 'Gothic A1', sans-serif;
    font-weight: 400;
    transition: all 0.3s ease-in-out;
    filter: drop-shadow(0 0px 0px rgb(0, 0, 0, 0.4));
    
    &:hover {
        border: none;
        background-color: black;
        color: white;
        filter: drop-shadow(0 2px 2px rgb(0, 0, 0, 0.4));
      }
  }